<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Category</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="category-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-text-field
            label="Tagline"
            v-model="fields.tagline"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tagline')"
            :error-messages="errors['tagline']"
          ></v-text-field>
          <v-textarea
            label="Description"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-textarea
            label="Short Description"
            v-model="fields.short_description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>
          <v-file-input
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
          ></v-file-input>
          <v-switch
            label="Is this a 'Parent' category?"
            v-model="fields.is_parent"
            color="green"
            class="mt-1 mb-4"
            inset
          ></v-switch>
          <v-switch
            label="Is this a branded category?"
            v-model="fields.is_brand"
            color="green"
            class="mt-1 mb-4"
            inset
          ></v-switch>
          <v-switch
            label="Do you want this category to be included in the Quick Links?"
            v-model="fields.is_quick_link"
            color="green"
            class="mt-1 mb-4"
            inset
          ></v-switch>
          <v-select
            label="Parent Categories"
            v-model="fields.parents"
            :items="parentCategories"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            background-color="white"
            :error="errors.hasOwnProperty('parents')"
            :error-messages="errors['parents']"
          ></v-select>
          <v-card elevation="2" class="mb-8">
            <v-card-subtitle>Category Type Details</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
              <v-select
                label="Category Type *"
                :items="types"
                item-value="value"
                item-text="label"
                v-model="fields.template_type"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('template_type')"
                :error-messages="errors['template_type']"
              ></v-select>
              <StraightDetailsForm
                ref="straightDetails"
                :category="category"
                v-if="fields.template_type === 'straights'"
              />
            </v-card-text>
          </v-card>
          <v-text-field
            label="Display Order"
            v-model="fields.display_order"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('display_order')"
            :error-messages="errors['display_order']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="category-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StraightDetailsForm from "./StraightDetailsForm";
export default {
  components: {
    StraightDetailsForm,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        slug: null,
        tagline: null,
        description: null,
        short_description: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        is_parent: false,
        is_brand: false,
        is_quick_link: false,
        parents: [],
        template_type: null,
        display_order: null,
      },
      category: null,
      errors: {},
      types: [
        { value: "mixtures", label: "Mixtures" },
        { value: "straights", label: "Straights" },
      ],
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    parentCategories() {
      return this.$store.state.watson.products["categories"].filter(c => {
        return c.is_parent === true;
      });
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    openForm: function(category = null) {
      if (category !== null) {
        this.isEditing = true;
        this.category = category;
        this.fields.name = category.name;
        this.fields.slug = category.slug;
        this.fields.tagline = category.tagline;
        this.fields.description = category.description;
        this.fields.short_description = category.short_description;
        this.fields.thumbnail = category.thumbnail
          ? new File([category.thumbnail], category.thumbnail)
          : null;
        this.fields.is_parent = category.is_parent;
        this.fields.is_brand = category.is_brand;
        this.fields.is_quick_link = category.is_quick_link;
        this.fields.parents = category.parents.map(c => c.id);
        this.fields.template_type = category.template_type;
        this.fields.display_order = category.display_order;
      }

      this.dialog = true;
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.slug = null;
      this.fields.tagline = null;
      this.fields.description = null;
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.short_description = null;
      this.fields.thumbnail = null;
      this.fields.is_parent = false;
      this.fields.is_brand = false;
      this.fields.is_quick_link = false;
      this.fields.parents = [];
      this.fields.template_type = null;
      this.fields.display_order = null;
      this.category = null;
      this.errors = {};
    },

    saveForm: function() {
      this.loading = true;
      this.errors = {};

      let fields = (type => {
        if (type === "straights") {
          return this.$refs.straightDetails.saveForm();
        }

        return null;
      })(this.fields.type);

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          ...fields,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.categoryId = this.category.id;
      }

      this.$store
        .dispatch("watson/products/saveCategory", payload)
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    thumbnailHasChanged: function() {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
